h3 {
  font-weight: bold;
}

h5 {
  text-decoration: underline;
}

.PricingPage {
  margin-top: 5%;
  margin-bottom: 10%;
}
.pricing-section {
  text-align: left;
  margin: 5% 20%;
  padding-bottom: 5%;
}

