
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlay-content {
    text-align: center;
    color: white;
    padding: 20px;
  }
  
  .art-card:hover .overlay {
    opacity: 1; /* Show the overlay on hover */
  }
  
  .artwork_image {
    max-width: 100%;
    height: auto;
    transition: filter 0.3s ease-in-out;
  }
  
  .art-card:hover .image {
    filter: brightness(50%); /* Darken the image on hover */
  }

  .g-4 .col {
    display: flex;
    align-items: stretch;
  }
  
  .g-4 .col > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .artwork-card-grid .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 20px;
  }
  /* custom-styles.css */
.custom-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 20px;
  }

  .sold-tab {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .art-card {
    position: relative;
    border: none;
    overflow: hidden;
  }
  
  .image-container {
    position: relative;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    object-fit: contain; /* Maintain aspect ratio without cropping */
  }
  
  
  