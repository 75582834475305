@font-face {
  font-family: 'LaLuxeSerif';
  src: url(../src/fonts/LaLuxesSerif.ttf);
}
h2 {
  /* font-size: 1em; */
  font-family: 'LaLuxeSerif', serif;
  text-align: center;
  line-height: 2;
  letter-spacing: 6vw;
  font-weight: bold;
}

h3, h4, h5 {
  font-family: 'LaLuxeSerif', serif;

}

.App {
  text-align: center;
  line-height: 2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

