h2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: normal;

}
.fluid-container {
  width: auto;
  margin: 5%;
  text-align: center;
}

.pilates-img {
  width: 100%;
  margin-left: auto;
  margin-bottom: 2rem;
}

/* .stretching-header {
  font-size: calc(0.4rem + 1vw);
  letter-spacing: .7vw;
} */

