h3 {
    font-weight: 400;
    font-size: 1.2em;
}
p {
    /* font-size: large; */
    font-size: 1em;
    padding-top: 1rem;
}

.banner{
    height:10rem;
    width: 100%;
}

.index-intro{
    width:100%;
    background-color: #f3f3f3;
}

.index-intro .jumbotron {
    padding: 0px;
}

.banner-image {
    position: relative;
    width: 100%; /* for IE 6 */
 }

 .banner-title{
     color: white;
     position: absolute;
     top: 40%;
     left: 0;
     width: 100%;
 }

.header {
    text-align: center;
}

.studio-section{
    padding: 5% 0 5% 0;
    font-weight: bold;
 }

.studio-description {
    padding: 0% 5%;

}

.studio-slideshow {
    padding-bottom: 5%;
}

@media (max-width: 900px) {
    .carousel {
      width:auto;
      height:auto;
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .mia_banner {
        height: auto; /* Adjust the height for mobile view */
        margin-top: -2rem; /* Remove top margin for mobile view */
        margin-bottom: -2rem; /* Remove bottom margin for mobile view */
      }
    
      .banner-background {
        display: none; /* Hide the background on mobile */
      }
    
      /* Adjust the padding of the section below the banner for mobile view */
      .about-mia,
      .studio-section {
        padding: 1rem 0;
      }
  }

.mia_banner {
    position: relative;
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
  }
  
  .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/mia_banner.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1;
  }
  
  .main-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%; /* Make the main image container match the height of the banner */
  }
  
  .main-image-container img {
    max-width: 100%;
    max-height: 100%; /* Set the max height to ensure the image fits within the container */
  }
  

  .about-mia {
    /* padding-top: 2rem; */
    padding: 5%;


  }
  
  

.card {
    width:100%;
}

.card-deck {
    padding-top: 3rem;
}

.col-lg-4.col-12 {
    padding-bottom: 5%;
}

/* ****About Section**** */

/* .about-mia {
    padding-bottom: 10%;
} */

#testimonials {
    text-align: center;
    padding-bottom: 5%;
}

#testimonials .w-100 {
    height: 15rem;
}

.carousel {
    border:0px !important;
}
.carousel-caption {
    color: #c7b975;
}

#testimonials h3 {
    font-size: 1em;
}

/* Used to change the color of the indicators by changing the bootstrap icon */
#testimonials .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dd9866' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }

#testimonials .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dd9866' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  /* color:#dd9866; */
}

/* change colors of the non active slides */
#testimonials .carousel-indicators li{
    background-color: #e4b18d;
}

/* change colors of the active slides */

#testimonials .carousel-indicators .active{
    background-color: #dd9866;
}


